/*
 * insta_connection.js
 * Author: Lily Li
 */

import React from 'react';
import axios from "axios";
import './insta.css'

export default class Insta extends React.Component {
  state = {
    instaData: [],
  }

  async componentDidMount() {
    let data = [];
    const url = "https://www.instagram.com/ijetlab/?hl=en";
    const instaSource = await axios.get(url);

    const instaObj = instaSource.data
      .match(
        /<script type="text\/javascript">window\._sharedData = (.*)<\/script>/
      )[1]
      .slice(0, -1);

      const convertedJsonObj = JSON.parse(instaObj);
      console.log(convertedJsonObj, "JSON OBJECT")
      const mediaArr = convertedJsonObj.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges
      
        mediaArr.map(el => {
          const node = el.node;
          data.push(node);
        })
        this.setState({ instaData: data });
    }

    renderInstaPics = () => {
      return this.state.instaData.map((media, index) => {
        return (
          <div id="insta-child-div" key={index}>
            <img
              src={media.thumbnail_src}
              alt="insta"
              id="insta-img"
            />
          </div>
        )
      })
    }
    
    render() {
      return(
        <div id="insta-div">
          {this.state.instaData ? this.renderInstaPics() : ""}
        </div>
      )
    }
}
