/*
 * index.js
 * Author: Lily Li
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './css/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import banner from './assets/brunswick-stock1.jpg'
import {Home, About, Culture, Vision, Brunswick, WhatWeDo, Contact, Newsletter} from './pages/'
import Header from './components/header/header'

ReactDOM.render(
  <React.StrictMode>
    <Header hero_img={banner}/>
    <Router>
      <Switch>
          <Route path="/home" exact component={Home} />
          <Route exact path="/"> <Redirect to="/home" /> </Route>
          <Route path="/about" exact component={About} />
          <Route path="/culture" exact component={Culture} />
          <Route path="/vision" exact component={Vision} />
          <Route path="/brunswick" exact component={Brunswick} />
          <Route path="/what-we-do" exact component={WhatWeDo} />
          <Route path="/newsletter" exact component={Newsletter} />
          <Route path="/contact" exact component={Contact} />
      </Switch>
    </Router>
  </React.StrictMode>,
  
  document.getElementById('root')
);
