/*
 * quote.js
 * Author: Lily Li
 */

import React from 'react';
import './quote.css';
import '../../css/theme.css';
import {motion} from 'framer-motion'
import Paragraph from '../paragraph/paragraph'

import quote_texture from '../../assets/quote-texture.png'

function Quote(props){
    return(
        
        <div className="quote-div">
            <img src={quote_texture} id="quote_texture" style={{visibility: props.showTexture ? 'visible' : 'hidden' }}/>
            <motion.div
            animate={{ scale: [0.9,1] }}
            transition={{ duration: 0.5}}
            style={{zIndex:"501"}}
            >
                <img src={props.img} alt="banner" id="quote-img-col"/> 
            </motion.div>
            <motion.div
            x={"-100%"}
            animate={{ x:0 }}
            transition={{ duration: 0.5 }}
            style={{zIndex:"500"}}
            >
                <div id="quote-text-col"> 
                    <p className="quote-paragraph">{props.quote}</p> 
                    <h6 style={{textAlign:"left", color:"var(--darkblue)"}}>&mdash; {props.info} </h6>
                </div>
            </motion.div>
        </div>
    );
}

function Quote2(props){
    return(
        <div className="quote2-div">
            <img src={props.background_img} id="quote-background-img"/>
            <Paragraph heading={props.heading} subheading={props.subheading} style={{zIndex:"100"}}/>
            <div id="quote2-content-div">
                <div id="quote2-img-col">
                    <motion.div
                    animate={{ scale: [0.9,1] }}
                    transition={{ duration: 0.5 }}
                    >
                    <img src={props.img}/>
                    </motion.div>
                    <br/>
                    <motion.div
                    x={"-100%"}
                    animate={{ x:0 }}
                    transition={{ duration: 0.5 }}
                    >
                    <p className="paragraph-italics">{props.quote}</p>
                    <h6 style={{textAlign:"left", color:"var(--darkblue)"}}>&mdash; {props.info}</h6>
                    </motion.div>
                </div>
                <div id="quote2-text-col">
                    <Paragraph paragraphs={props.paragraphs}/>
                </div>
            </div>
        </div>
    )
}

export {Quote, Quote2}