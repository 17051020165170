/*
 * vision.js
 * Author: Lily Li
 */

import React from 'react';
import '../css/theme.css'

import Lily from '../assets/headshot-lilyli.jpg'
import {SubPageHero} from '../components/hero/hero'
import Paragraph from '../components/paragraph/paragraph'
import {Quote} from '../components/quote/quote'
import {CircleImageBackgroundTable} from '../components/table/circle_table'
import PageNav from '../components/pagenavigation/page_navigation'
import texture_img from '../assets/blocks.png'
import Footer from '../components/footer/footer'

import brunswick1 from '../assets/brunswick-stock6.jpg'
import brunswick2 from '../assets/brunswick-stock4.jpg'
import vision_hero from '../assets/vision-hero.JPG'
import vision_circle1 from '../assets/vision-circle1.JPG'
import vision_circle2 from '../assets/vision-circle2.JPG'
import vision_circle3 from '../assets/vision-circle3.JPG'
import vision_circle4 from '../assets/vision-circle4.JPG'
import vision_circle5 from '../assets/vision-circle5.JPG'


export default function Culture(){
    const items = [{heading: "Autonomous Navigation", img:vision_circle4}, {heading: "Electric Mobility", img:vision_circle2}, {heading: "Frictionless UI/UX", img:vision_circle3},{heading: "Talent Development", img:vision_circle1},{heading: "Talent Pipeline", img:vision_circle5}];
    return(
        <div className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", width: "100vw", padding:"0vw 10vw"}}>
            <SubPageHero texture_img={texture_img} hero_img={vision_hero} title={"VISION"}/>
            <Paragraph heading="A VISION FOR THE FUTURE OF BOATING" paragraphs={["At the Brunswick i-Jet Lab, we're driven by a desire to effectively influence and enhance the internship experience and the way our businesses operate. We have focused on this goal by investing our efforts into key categories that guide our work."]}/>
            <CircleImageBackgroundTable items={items}/>
            <Paragraph heading="THE FUTURE OF OUR CUSTOMERS" paragraphs={["As the innovative branch of Brunswick, i-Jet Lab works closely with top leadership team to identify opportunities for improvement and new technologies. We think in the long term to anticipate future trends in order to adapt and utilize the newest technologies. At i-Jet, we’re paving the path for our company to dominate the marine industry with innovative and modern solutions and experiences."]}/>
            <Quote img={Lily} info={"Lily Li, Software Development Intern"} quote={"I joined i-Jet with hopes of gaining technical and professional experiences, but in actuality, I gained way more than just experiences. With a welcoming and collaborative environment, I was able to easily befriend interns of other disciplines and learn from their areas of expertise."}/>
            <PageNav img1={brunswick1} img2={brunswick2} heading1="WHAT WE DO" subheading1="See Us in Action" url1="/what-we-do" heading2="BLOG" subheading2="Read Our Thoughts" url2="/blog"/>
            <Footer />
        </div>
    );
}