/*
 * home.js
 * Author: Lily Li
 */

import React from 'react';
import '../css/theme.css'
import main_hero from '../assets/main-hero.JPG'
import {MainHero} from '../components/hero/hero'
import Paragraph from '../components/paragraph/paragraph'
import {ButtonContainer} from '../components/button/button'
import {Quote} from '../components/quote/quote'
import {CircleImageBackgroundTable} from '../components/table/circle_table'
import HomeFooter from '../components/footer/home_footer'
import home_circle1 from '../assets/home-circle1.jpg'
import home_circle2 from '../assets/home-circle2.JPG'
import home_circle3 from '../assets/home-circle3.JPG'
import trevor from '../assets/headshot-trevor.jpg'

export default class Home extends React.Component{
    constructor(){
        super(); 
    }

    render(){
        const items = [{heading: "Engineering", img:home_circle3}, {heading: "Software", img:home_circle1}, {heading: "Design", img:home_circle2}];
        return(
            <div id="top" className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", width: "100vw !important", padding:"0vw 10vw"}}>
                <MainHero hero_img={main_hero}/>
                <div>
                    <Paragraph heading={"WE FACE CHALLENGES HEAD ON"}
                    paragraphs={["At the Brunswick i-Jet lab, we focus on generating creative solutions in autonomy and electrification through multi-disciplinary collaboration and the exploration of emerging technologies. With curiosity and passion, our interns channel their creativity into production-ready prototypes that accelerate technology readiness within Brunswick.",
                    "We are an innovation hub and we combine ideas, design, and engineering to develop technical solutions for Brunswick’s brands. As a conglomerate, Brunswick Corporation owns many of the largest brands within the marine industry, including marquee names such as Mariner, Mercury Marine, Boston Whaler, and many more. At i-Jet, we use cutting-edge technology to provide support to these brands and add value to the corporation.",
                    "We work in a modern and fun workspace. We have board games, a fully stocked snack bar, a Switch, an espresso machine, you name it! Moreover, we have easy-access to ping-pong and foosball tables within the building. Outside of work, we often hang out after hours and have regular team parties and boat outings."]}/>
                    <ButtonContainer 
                        items={[{href:"./about", name:"LEARN MORE"}]}
                    />
                </div>
                <div class="lightblue-div">
                    <Paragraph heading={"PROJECT AREAS"} />
                    <CircleImageBackgroundTable items = {items}/>
                </div>
                <div>
                    <Paragraph heading={"PICTURE YOURSELF AT I-JET"}/>
                    <ButtonContainer 
                        items={[{href:"./culture", name:"CULTURE"}, {href:"./what-we-do", name:"WHAT WE DO"}, {href:"./newsletter", name:"NEWSLETTER"}]}
                    />
                </div>
                <Quote img={trevor} showTexture={true} info={"Trevor George, Site Director"} quote={"Here at i-Jet, we believe in pursuing our interests and pushing the boundaries of what is possible in the marine industry. We believe we have the potential to enhance the internship experience and the way our business operates. We see technology and innovation as enablers to a frictionless boating experience. Most importantly, we believe in the power of constant collaboration and exploration. We are the future of boating. We are i-Jet."}/>
                <HomeFooter />
            </div>
        );
    }
}