/*
 * newsletter_table.js
 * Author: Lily Li
 */

import React from 'react';
import './newsletter_table.css'
import {motion} from 'framer-motion'

import june_2020 from '../../assets/blog-6-20.jpg'
import july_2020 from '../../assets/blog-7-20.jpg'
import august_2020 from '../../assets/blog-8-20.jpg'
import october_2020 from '../../assets/blog-10-20.JPG'
import february_2021 from '../../assets/blog-2-21.jpg'
import april_2021 from '../../assets/blog-4-21.JPG'
import june_2021 from '../../assets/blog-6-21.jpg'
import july_2021 from '../../assets/blog-7-21.jpg'
import august_2021 from '../../assets/blog-8-21.jpg'
import september_2021 from '../../assets/blog-9-21.jpg'
import october_2021 from '../../assets/blog-10-21.jpg'
import november_2021 from '../../assets/blog-11-21.jpg'
import december_2021 from '../../assets/blog-12-21.jpg'
import january_2022 from '../../assets/blog-1-22.jpg'
import february_2022 from '../../assets/blog-2-22.jpg'
import march_2022 from '../../assets/blog-3-22.jpg'



import Jun2020 from '../../files/2020_06.pdf'
import July2020 from '../../files/2020_07.pdf'
import Aug2020 from '../../files/2020_08.pdf'
import Oct2020 from '../../files/2020_10.pdf'
import Feb2021 from '../../files/2021_02.pdf'
import April2021 from '../../files/2021_04.pdf'
import June2021 from '../../files/2021_06.pdf'
import July2021 from '../../files/2021_07.pdf'
import August2021 from '../../files/2021_08.pdf'
import September2021 from '../../files/2021_09.pdf'
import October2021 from '../../files/2021_10.pdf'
import November2021 from '../../files/2021_11.pdf'
import December2021 from '../../files/2021_12.pdf'
import January2022 from '../../files/2022_01.pdf'
import February2022 from '../../files/2022_02.pdf'
import March2022 from '../../files/2022_03.pdf'
    
import bullet from '../../assets/icon-bullet.png'

const pdfs = [
    { header: "March 2022", text: "i-Jet gears up for spring", img: march_2022, pdf: March2022 },
    { header: "February 2022", text: "i-Jet recruits for the summer", img: february_2022, pdf: February2022 },
    { header: "January 2022", text: "i-Jet gets ready for the new year!", img: january_2022, pdf: January2022 },
    { header: "December 2021", text: "i-Jet looks forward to winter break", img: december_2021, pdf: December2021 },
    { header: "November 2021", text: "i-Jet prepares for fall break", img: november_2021, pdf: November2021 },
    {header: "October 2021", text: "i-Jet welcomes the fall", img: october_2021, pdf: October2021},
    {header: "September 2021", text: "i-Jet celebrates its grand opening", img: september_2021, pdf: September2021},
    {header: "August 2021", text: "i-Jet lab wraps up summer", img: august_2021, pdf: August2021},
    {header:"July 2021", text:"i-Jet Lab moves into our new home", img: july_2021, pdf: July2021},
    {header: "June 2021", text: "i-Jet takes a dive into summer", img: june_2021, pdf: June2021},
    {header:"April 2021", text:"i-Jet Lab gets ready for graduation", img: april_2021, pdf: April2021},
    {header:"February 2021", text:"i-Jet Lab springs in to the new year!", img: february_2021, pdf: Feb2021},
    {header:"October 2020", text:"i-Jet Lab advances in to fall semester", img: october_2020, pdf: Oct2020},
    {header:"August 2020", text:"Tech Talks from the i-Jet Lab Interns", img: august_2020, pdf: Aug2020},
    {header:"July 2020", text:"i-Jet Lab interns “Be their Best” this summer", img: july_2020, pdf: July2020},
    {header:"June 2020", text:"i-Jet Lab welcomes its summer cohort", img: june_2020, pdf: Jun2020}
];
function NewsletterTable(props){

    const [hide, setHide] = React.useState({ 
        hide: window.innerWidth > 1000 ? true : false
    });
    React.useEffect(() => {
        function handleResize() {
          setHide({
            hide: window.innerWidth > 1000 ? true : false
          })
    }
        window.addEventListener('resize', handleResize)
    })

    var rows = []
    for(var i in pdfs){
        rows.push(
            <Row hide={hide.hide} header={pdfs[i].header} text={pdfs[i].text} img={pdfs[i].img} pdf={pdfs[i].pdf} style={props.style} snippet={props.snippet}/>
        );
    }
    return(
        <div className="links-table-div">
            {rows}
        </div>
    );
}

function Row(props){
    return(
        <a
        href={props.pdf} 
        target='_blank'
        id="row-div"
        style={{height:"50px !important"}}
        >
            <motion.div
            whileHover={{
                scale: 1.01
            }}
            animate={{ scale: [0.9,1] }}
            whileTap={{ scale: 0.995 }}
            transition={{duration: 0.5}}
            id="row-div"
            style={{height:"50px !important", boxShadow:"7px 7px 7px -8.9px rgba(0,0,0,.5)"}}
            >
            <div id="img-div">
                <img src={props.img} id="img-div-img"/>
            </div>
            {!props.snippet && <div id="text-div">
                <h2 style={{textAlign:"left", fontFamily:"AvenirNext", color:"var(--darkblue)"}}>{props.header}</h2>
                {props.hide && <p>{props.text}</p>}
            </div>}
            {props.snippet && <div id="text-div">
                <h6 style={{textAlign:"left", color:"var(--darkblue)"}}>{props.header}</h6>
                <p className="subscript">{props.text}</p>
            </div>}
            </motion.div>
        </a>
    )
}

function SnippetRow(props){
    return(
        <a
        href={props.pdf} 
        target='_blank'
        id="snippet-row-div">
            <img src={bullet} style={{height:"20px", width:"20px"}}/>
            <div id="snippet-text-div">
                <p style={{textAlign:"left", width:"100%", margin: "0", color:"var(--darkblue)"}} className="subheading">{props.header}</p>
                <p style={{textAlign:"left", width:"100%", margin: "0", color:"var(--blue)"}} className="paragraph-italics">{props.text}</p>
            </div>
        </a>
    )
}

function SnippetNewsletter(props){
    var rows = []
    for(var i =0; i < 4; i++){
        rows.push(
            <SnippetRow header={pdfs[i].header} text={pdfs[i].text} pdf={pdfs[i].pdf} style={props.style}/>
        );
    }
    return(
        <div id="links-table-div">
            {rows}
        </div>
    );
}

export {NewsletterTable, SnippetNewsletter};