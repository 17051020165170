/*
 * about.js
 * Author: Lily Li
 */

import React from 'react';
import '../css/theme.css'

import Footer from '../components/footer/footer'
import {PageHero} from '../components/hero/hero'
import Slides from '../components/carousel/carousel'
import FlipCards from '../components/flipcard/flipcard'
import BoxTable from '../components/table/box_table'
import texture_img from '../assets/blocks.png'
import about_circle1 from '../assets/about-circle1.jpg'
import about_circle2 from '../assets/about-circle2.JPG'
import about_circle3 from '../assets/about-circle3.jpg'
import about_circle4 from '../assets/about-circle4.jpg'
import about_circle5 from '../assets/about-circle5.JPG'
import about_hero from '../assets/about-hero.jpg'
import about_slider1 from '../assets/about-slider1.jpg'
import about_slider2 from '../assets/about-slider2.JPG'
import about_table1 from '../assets/about-table1.jpg'
import about_table3 from '../assets/brunswick-stock1.jpg'
import about_table2 from '../assets/headshot-trevor1.jpg'

const pictures = [about_circle1, about_circle2, about_circle3, about_circle4, about_circle5, about_hero, about_slider1, about_slider2, about_table1, about_table2, about_table3]
const table = 
        [{header:"Our Culture", text:"When you work at the i-Jet, you work alongside other talented students from the University and professionals across the globe. With a start-up feel in the office, we work hard and play hard. Our physical workspace is modern and cross-functional. We are a community of out-of-box thinkers, problem solvers, innovators, and there’s-no-challenge-too-big sayers. i-Jet believes that to foster growth, there must be failures; but through hands-on collaboration and inspiration, our team can overcome any challenge.", img:about_table1, link:"/culture", linkText:"Get a feel of our team and workspace"},
        {header:"Meet Trevor", text:"Trevor George is the face of i-Jet Lab as our Site Director. After graduating from The University of Wisconsin–Madison with a Bachelor’s in Mechanical Engineering, Trevor joined Mercury Marine (a subsidiary of Brunswick) and has been with the company ever since! Trevor is a great mentor and supervisor to the students, and he is always eager to guide students through new ideas, projects, and anything else!", img:about_table2, link:""}, 
        {header:"About Brunswick",text:"Brunswick Corporation is a conglomerate in the marine industry with over 80 brands. Brunswick is defining, creating, and innovating the future of recreational boating. With advanced design, technology, and innovation, Brunswick redefines the field while advancing our leadership position in the industry. i-Jet lab and Brunswick works together to generate ideas and goals for our space. Together, we’re able to make some pretty creative things.", img:about_table3, link:"/brunswick", linkText:"Learn more about Brunswick"}]

    const slides = [{background_img:about_slider1, title:"WHO WE ARE", description:"We use a combination of Agile and CI/CD (continuous integration and continuous deployment) methodologies in our development pipelines. In every project, we utilize design thinking and incorporate user-centered designs. Every team gets the opportunity to work with and explore up and coming technologies and develop their own strategies and critical approaches for every aspect of a project."}, 
                {background_img:about_slider2, title:"OUR APPROACH", description:"Collaboration is a key aspect of our daily work at the Lab. Every project spans across multiple disciplines and includes members from different educational backgrounds. We believe that cross-functional groups are stronger and create more innovative solutions than those of monodisciplinary teams. Moreover, colleagues are encouraged to learn from each other and pick up new skills and interests."}]
       
    const cards = [{primary:"3", secondary:"years old", img: about_circle5}, 
    {primary:"10", secondary:"converted full time", img: about_circle2}, 
    {primary:"80+",secondary:"Brunswick brands", img: about_circle1}, 
    {primary:"4",secondary:"full-time employees", img: about_circle4}, 
    {primary:"18",secondary:"interns", img: about_circle3}]
    
export default class About extends React.Component{
    componentDidMount(){
        pictures.forEach((image) => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
        });
    }
    render(){
        return(
            <div className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", width: "100vw", padding:"0vw 10vw"}}>
                <PageHero texture_img={texture_img} hero_img={about_hero} title={"EXPLORE THE LAB"} subtitle={"See what inspiration is all about"}/>      
                <Slides items={slides}/>
                <FlipCards items={cards}/>
                <BoxTable items={table}/>
                <Footer/>
            </div>
        );
    }
}