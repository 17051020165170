/*
 * whatwedo.js
 * Author: Lily Li
 */

import React from 'react';
import '../css/theme.css'

import {PageHero} from '../components/hero/hero'
import {TableCellWithBackgroundImg} from '../components/table/table'
import {CircleTable} from '../components/table/circle_table'
import Paragraph from '../components/paragraph/paragraph'
import GridTable from '../components/table/grid_table'
import Footer from '../components/footer/footer'

import texture_img from '../assets/blocks.png'
import whatwedo_hero from '../assets/whatwedo-hero.jpg'
import whatwedo_table from '../assets/brunswick-stock5.jpg'
import whatwedo_teams1 from '../assets/whatwedo-teams1.JPG'
import whatwedo_teams2 from '../assets/whatwedo-teams2.jpg'
import whatwedo_teams3 from '../assets/whatwedo-teams3.jpg'

import icon_autonomy from '../assets/icon-autonomy.png'
import icon_vr from '../assets/icon-vr.png'
import icon_data from '../assets/icon-analytics.png'
import icon_electrical_system from '../assets/icon-electrical-systems.png'
import icon_ar from '../assets/icon-ar.png'
import icon_power_electronics from '../assets/icon-power-electronics.png'


export default function WhatWeDo(){
    const teams = [{header: "Engineering", 
        text:"Great inventions come from talented engineers. At the i-Jet Lab, our electrical and mechanical engineering interns work together to solve challenging problems and develop innovative solutions. We work with top-of-the-line hardware and even build our own robots.", img:whatwedo_teams3}, 
    {header: "Software", 
        text:"Our software development interns create impressive websites, mobile apps, AR/VR experiences, and more. We work closely with our electrical engineering interns on projects pertaining to robotics, computer vision, and more.", img:whatwedo_teams2}, 
    {header: "Design", 
        text:"Graphic and industrial design interns at the i-Jet Lab go beyond the limits of UI/UX, design thinking, and innovative product design. The presentation of our ideas and having user-focused experiences are critical and our design interns bring it.", img:whatwedo_teams1}];
    const items = [{heading: "Power Electronics", img: icon_power_electronics}, {heading: "Electrical Systems", img:icon_electrical_system}, {heading: "Virtual Reality", img:icon_vr},{heading: "Augmented Reality", img:icon_ar},{heading: "Data Analysis", img:icon_data},{heading: "Autonomous Vehicles", img:icon_autonomy}];
    return(
        <div className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", alignContent:"center", justifyContent:"center", justifyItems:"center", width: "100vw", padding:"0vw 10vw"}}>
            <PageHero texture_img={texture_img} hero_img={whatwedo_hero} title={"CHECK OUT THE TEAM"} subtitle={"Creativity is at the core of what we do"}/>
            <TableCellWithBackgroundImg img={whatwedo_table} header={"WHAT WE DO"} 
                description={["At the i-Jet Lab, we’re driven by the goal of modernizing the recreational boating experience. Here, we create prototypes for mobile apps, websites, products and more that exponentially improve the experiences of our customers. We are committed to this mission. To meet these goals, we continuously invest in state-of-the-art technology, and industry-leading processes and methods.", 
                "All of the projects in the Lab support the various brands of our parent company, Brunswick. Some of the most notable brands include Mercury Marine, Mariner, Boston Whaler, and SeaRay. i-Jet Lab also supports Brunswick’s newer brands, including Nautic-ON, a unique start-up in the company aimed at enabling advanced, remote monitoring of a boat’s status."]}/>
            <>
            <Paragraph heading={"TECHNOLOGIES"}/>
            <CircleTable items={items}/>
            </>
            <>
            <Paragraph heading={"CONCENTRATIONS"} subheading={"Our collective skillset is stacked"}/>
            </>
            <GridTable items={teams}/>
            <Footer />
        </div>
    );
}