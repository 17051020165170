/*
 * culture.js
 * Author: Lily Li
 */

import React from 'react';
import '../css/theme.css'

import Anna from '../assets/headshot-annachi.jpg'
import {SubPageHero} from '../components/hero/hero'
import Paragraph from '../components/paragraph/paragraph'
import {Quote} from '../components/quote/quote'
import {Table} from '../components/table/table'
import PageNav from '../components/pagenavigation/page_navigation'
import Footer from '../components/footer/footer'

import brunswick1 from '../assets/brunswick-stock6.jpg'
import brunswick2 from '../assets/brunswick-stock4.jpg'
import texture_img from '../assets/blocks.png'
import culture_hero from '../assets/culture-hero.jpg'
import culture_table1 from '../assets/culture-table1.jpg'
import culture_table2 from '../assets/culture-table2.JPG'
import culture_table3 from '../assets/culture-table3.JPG'

export default function Culture(){
    const table = [{header:10, description: "office group events every year", img:culture_table1}, {header: 3, description: "smart speakers", img:culture_table2}, {header:1, description:"industrial-grade espresso machine", img:culture_table3}]
    return(
        <div className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", width: "100vw", padding:"0vw 10vw"}}>
            <SubPageHero texture_img={texture_img} hero_img={culture_hero} title={"Culture"}/>
            <Paragraph heading="SEE OUR CULTURE" subheading="A balance of work and play" 
            paragraphs={["As soon as you step into i-Jet, you’ll experience our casual but focused atmosphere. You’ll find us chatting around the snack bar, collaborating in the conference rooms, or relaxing in our media space. Although the choice of office music may cause an occasional argument or two, overall, we’re a solid bunch. We are often seen taking our breaks outside, whether it’s playing ultimate frisbee or taking a relaxing walk around Research Park.",
                "Our office has Amazon Alexas to blast music, board games to pass the time, a Switch to play Mario Kart, and an espresso machine for those early mornings. In the past, we have had excursions kayaking at Kickapoo State Park, boat riding in Indianapolis, and online game nights playing JackBox games."]}/>
            <Table sections={table}/>
            <Paragraph heading="COLLABORATION" paragraphs={["Collaboration is the driving force at the i-Jet Lab. Idea-sharing is necessary for us to continually innovate and improve our workflow and solutions. We have open seating at the office, along with communal long-tables, plenty of whiteboards, and more. This modern open layout in the office encourages effective teamwork, communication, and collaboration. Our cross-functional teams are composed of multidisciplinary interns who provide unique values and perspectives to each project."]}/>
            <Quote img={Anna} info={"Anna Chi, Industrial Design Intern"} quote={"One thing I appreciate about i-Jet lab most of all is the flexibility we’re given in choosing our tasks. We are assigned projects, but also encouraged to learn about other projects going on in the lab and step in if we want to work on them too. It really helps fuel the innovation that happens in the lab because people genuinely want to work on the projects they’re a part of."}/>
            <PageNav img1={brunswick1} img2={brunswick2} heading1="WHAT WE DO" subheading1="See Us in Action" url1="/what-we-do" heading2="BLOG" subheading2="Read Our Thoughts" url2="/blog"/>
            <Footer />
        </div>
    );
}