/*
 * contact.js
 * Author: Lily Li
 */

import React, {Component} from 'react';
import '../css/theme.css'
import GoogleMapReact from 'google-map-react';
import marker from '../assets/icon-map.png'
import Footer from '../components/footer/footer'
import {TableCellWithBackgroundImg} from '../components/table/table'
import {PageHero} from '../components/hero/hero'
import Paragraph from '../components/paragraph/paragraph'
import {CircleBackgroundTable} from '../components/table/circle_table'
import texture_img from '../assets/blocks.png'
import contact_hero from '../assets/contact-hero.JPG'
import table_background from '../assets/brunswick-stock7.jpg'

const Marker = () => <div style={{width: "fit-content", height:"100px"}}><img src={marker} style={{width: "auto", height:"100px"}}/></div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 40.0938516,
            lng: -88.2354564
        },
        zoom: 15
    };
    render(){
        const style = {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                    { visibility: "off" }
            ],
            position: 'relative',  
            width: '100%',
            height: '400px',
            display: "block",
            overflowX: "hidden",
            overflowY: "hidden"
        }
        const containerStyle = {
            width: '100%',
            maxHeight: '400px',
        };

        const items = [{heading:"Address", text: "1907 S 4th St, Champaign, IL 61820"}, 
        {heading:"Email", text: "ijetlab@brunswick.com"}];
        return(
            <div className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", width: "100vw", padding:"0vw 10vw"}}>
                <PageHero texture_img={texture_img} hero_img={contact_hero} title={"CONTACT US"} subtitle={"Let’s start a conversation"}/>
                <CircleBackgroundTable items={items}/>
                <Paragraph heading="LOCATION" subheading="Find us here"/>
                <div className="map-wrapper" style={{ marginBottom: "0 !important", zIndex:"1000", overflowY:"hidden",width: "100vw", height:"400px",lineHeight: "0px", margin:"0 !important", padding:"0 !important"}}>
                    <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyBoEYYJN800oxCzBoJQMJmbmjl3HSSPWd4"}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    maxZoom={16}
                    >
                    <Marker
                        lat={40.093851}
                        lng={-88.235896}
                    />
                    </GoogleMapReact>
                </div>
                <TableCellWithBackgroundImg img={table_background} header={"SAY HI TO US AT EVENTS"} 
                description={["i-Jet Lab hosts semi-annual open house events where students and the community get a glimpse into the most recent projects we are working on. If you are interested in visiting our office, please be on the lookout for promotional posts on our social media.", 
                "We are a proud sponsor and attendee to the annual Research Park Career Fair in the Spring. At this event, students get to meet the current interns, full-time employees, and the site director. This is a great opportunity for any students interested in an internship.",
                "i-Jet Lab also attends the Grainger College of Engineering Career Fair in the Fall. Brunswick sends representatives from many of our brands to this event, which is perfect for any students interested in full-time opportunities within the corporation. i-Jet lab also recruits interns at this event."]}/>
                <Footer />
            </div>
        );
    }
} 
export default Contact;

// export default GoogleApiWrapper({
//     apiKey: ('AIzaSyAzl15-a9XLurbDOYawdYW8IbK7skXfKeg')
//    })(Contact);