/*
 * newsletter.js
 * Author: Lily Li
 */

import React from 'react';
import '../css/theme.css'

import {PageHero} from '../components/hero/hero'
import Paragraph from '../components/paragraph/paragraph'
import {NewsletterTable} from '../components/newslettertable/newsletter_table'
import Footer from '../components/footer/footer'

import texture_img from '../assets/blocks.png'
import block_hero from '../assets/blog-hero.jpg'

export default function Newsletter(){
    return(
        <div className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", width: "100vw", padding:"0vw 20vw"}}>
            <PageHero texture_img={texture_img} hero_img={block_hero} title={" NEWSLETTER"} subtitle={"Learn more about some topics that excite us"}/>
            <Paragraph heading={"MONTHLY NEWSLETTERS"}/>
            <NewsletterTable snippet={false}/>
            <Footer />
        </div>
    );
}