/*
 * header.js
 * Author: Lily Li
 */

import React from 'react';
import { Navbar,Nav, NavDropdown} from 'react-bootstrap';
import './header.css';
import logo from '../../assets/logo_partial_background.png'

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        // binds open() function
        this.open = this.open.bind(this);
        //sets scroll listeners 
        this.listener = null;
        this.state = {
          expanded: null,
          navbarClass: "navbar-primary",
          navbarItems: null
        };
      }
    
    //opens url
    open(url) {
        var win = window.location.replace(url);
    }

    //ran when page loads
    componentDidMount() {
        //adds scroll listener
        this.listener = document.addEventListener("scroll", e => {
          this.renameNavbarClassName();
        });
        this.listener = document.addEventListener("resize", e => {

          this.renameNavbarClassName();
          this.addNavbar();
        });

        if(window.innerWidth > 425){
          this.setState({ navbarClass: "navbar-primary" });
        }
        else{
          this.setState({ navbarClass: "navbar-not-expanded" });
        }
        this.addNavbar();
    }

    renameNavbarClassName(){
      var scrolled = document.scrollingElement.scrollTop;
      if(window.innerWidth >= 10000){
        if (scrolled >= 120) {
          this.setState({ navbarClass: "navbar-secondary" });
        }
        else{
          this.setState({ navbarClass: "navbar-primary" });
        }
      }
      else{
        if(this.state.expanded){
          this.setState({ navbarClass: "navbar-expanded" });
        }
        else if(scrolled >= 120){
          this.setState({ navbarClass: "navbar-secondary" });
        }
        else{
          this.setState({ navbarClass: "navbar-primary" });
        }
      }
      this.addNavbar();
      console.log("navbarClass: " + this.state.navbarClass);
    }

    addNavbar(){
      var navLinks = []
      if(window.innerWidth > 600 || (window.innerWidth >= 10000 && this.state.navbarClass==="navbar-primary") || this.state.navbarClass==="navbar-expanded"){
        navLinks.push(
          <Nav.Link href="/what-we-do"><p id="navbar-item">What We Do</p></Nav.Link>
        );
        navLinks.push(
        <Nav.Link href="/newsletter"><p id="navbar-item">Newsletter</p></Nav.Link>
        );
        navLinks.push(
          <Nav.Link href="/contact"><p id="navbar-item">Contact</p></Nav.Link>
        );
      }
      else{
        navLinks.push(
          <Nav.Link href="/about"><h5 id="navbar-item" className="subscript">Who We Are</h5></Nav.Link>
        );
        navLinks.push(
        <NavDropdown.Item  href="/culture"><h5 id="navbar-item" className="subscript">&ndash; Culture</h5></NavDropdown.Item>
        );
        navLinks.push(
          <NavDropdown.Item href="/vision"><h5 id="navbar-item" className="subscript">&ndash; Vision</h5></NavDropdown.Item>
        );
        navLinks.push(
          <NavDropdown.Item href="/brunswick"><h5 id="navbar-item" className="subscript">&ndash; Brunswick</h5></NavDropdown.Item>
        );
        navLinks.push(
          <Nav.Link href="/what-we-do"><h5 id="navbar-item" className="subscript">What We Do</h5></Nav.Link>
        );
        navLinks.push(
        <Nav.Link href="/newsletter"><h5 id="navbar-item" className="subscript">Newsletter</h5></Nav.Link>
        );
        navLinks.push(
          <Nav.Link href="/contact"><h5 id="navbar-item" className="subscript">Contact</h5></Nav.Link>
        );
      }
      this.setState({
        navbarItems: navLinks,
      });
    }
    
    //remove scroll listener
    componentDidUpdate() {
        document.removeEventListener("scroll", this.listener);
        document.removeEventListener("resize", this.listener);
    }

    toggleExpand(){
      if(this.state.expanded == null) { 
        this.setState({ expanded: true}, () => {
          this.renameNavbarClassName();
        }); 
      }
      else { 
        this.setState({ expanded: !this.state.expanded}, () => {
          this.renameNavbarClassName();
        }); 
      }
      this.addNavbar();
    }
    render() {
        return(
            <div className={this.state.navbarClass}>
              <a href="/"><img  id="logo-img" src={logo} alt="i-Jet logo" style={{...this.state.navbarClass=== "navbar-expanded" ? {visibility: "hidden"} : {visibility: "visible"}}}/></a>
              <Navbar collapseOnSelect expand='sm' id={this.state.navbarClass === "navbar-primary" ? "box-shadow" : ""} className="navbar" onToggle={()=> this.toggleExpand()}>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                  <Navbar.Collapse id="responsive-navbar-nav"> {/*navbar background*/}
                      <Nav className="mr-auto" id="nav"> {/*actual navbar*/}
                          <NavDropdown onClick={()=>this.open("/about")} href="/about" id="nav-dropdown" style={{whiteSpace:"nowrap"}} title={<p id="navbar-item">Who We Are</p>} renderMenuOnMount={true} style={{...this.state.navbarClass=== "navbar-expanded" ? {visibility: "hidden"} : {visibility: "visible"}}}>
                              <NavDropdown.Item  href="/culture" className="dropdown-item"><p>Culture</p></NavDropdown.Item>
                              <NavDropdown.Item href="/vision" className="dropdown-item"><p>Vision</p></NavDropdown.Item>
                              <NavDropdown.Item href="/brunswick" className="dropdown-item"><p>Brunswick</p></NavDropdown.Item>
                          </NavDropdown>
                          {this.state.navbarItems}
                      </Nav>
                  </Navbar.Collapse>
              </Navbar>
            </div>
        );  
    }
}