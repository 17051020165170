/*
 * footer.js
 * Author: Lily Li
 */

import React from 'react';
import './footer.css';
import {motion} from 'framer-motion'
import logo from '../../assets/logo_partial_background.png'
import instagram from '../../assets/logo-instagram.png'
import handshake from '../../assets/logo-handshake.png'
import linkedin from '../../assets/logo-linkedin.png'

export default class Footer extends React.Component {
    render() {
        return(
            <motion.div
            y={"100%"}
            animate={{ y:0 }}
            transition={{ duration: 0.5 }}
            id="footer-div"
            >
                <div id="logo-div" onClick={()=> window.open("/home", '_self')}>
                    <img id="logo-footer" src={logo} alt="i-Jet logo" style={{cursor:"pointer"}}/>
                </div>
                <div id="details-div">
                    <p className="paragraph" style={{fontSize:"12px"}}>1907 S. Fourth St., Suite 202, Champaign, IL 61820</p>
                    <p className="paragraph"> | </p>
                    <a href="https://brunswick.com" target='_blank'><p className="paragraph" style={{color:"var(--blue)", cursor:"pointer", fontSize:"12px"}}>Brunswick</p></a>
                    <p className="paragraph"> | </p>
                    <a href="https://researchpark.illinois.edu/" target='_blank'><p className="paragraph" style={{color:"var(--blue)", cursor:"pointer", fontSize:"12px"}}>Research Park</p></a>
                </div>
                <div id="social-media-div"> 
                    <a href="https://www.instagram.com/ijetlab" target='_blank' id="link">
                        <img id="social-media-img" src={instagram} alt="i-Jet logo"/>
                        <p className="paragraph" style={{color:"var(--blue)", fontSize:"12px"}}>Instagram</p>
                    </a>
                    <a href="https://illinois.joinhandshake.com/employers/79085" target='_blank' id="link">
                        <img id="social-media-img" src={handshake} alt="i-Jet logo"/>
                        <p className="paragraph" style={{color:"var(--blue)", fontSize:"12px"}}>Handshake</p>
                    </a>
                    <a href="https://www.linkedin.com/company/brunswick-corporation/" target='_blank' id="link">
                        <img id="social-media-img" src={linkedin} alt="i-Jet logo"/>
                        <p className="paragraph" style={{color:"var(--blue)", fontSize:"12px"}}>LinkedIn</p>
                    </a>
                </div>
            </motion.div>
        );
    }
}