/*
 * brunswick.js
 * Author: Lily Li
 */

import React from 'react';
import '../css/theme.css'

import {SubPageHero} from '../components/hero/hero'
import Paragraph from '../components/paragraph/paragraph'
import {Quote2} from '../components/quote/quote'
import {TableCellWithImages} from '../components/table/table'
import PageNav from '../components/pagenavigation/page_navigation'
import Footer from '../components/footer/footer'

import brunswick from '../assets/brunswick-stock5.jpg'
import brunswick1 from '../assets/brunswick-stock6.jpg'
import brunswick2 from '../assets/brunswick-stock4.jpg'
import texture_img from '../assets/blocks.png'
import brunswick_hero from '../assets/brunswick-hero.JPG'
import david_foulkes from '../assets/headshot-davidfoulkes.jpg'
import brand1 from '../assets/brunswick-brand1.png'
import brand2 from '../assets/brunswick-brand2.png'
import brand3 from '../assets/brunswick-brand3.png'
import brand4 from '../assets/brunswick-brand4.png'
import brand5 from '../assets/brunswick-brand5.png'
import brand6 from '../assets/brunswick-brand6.png'
import brand7 from '../assets/brunswick-brand7.png'
import brand8 from '../assets/brunswick-brand8.png'
import brand9 from '../assets/brunswick-brand9.png'
import brand10 from '../assets/brunswick-brand10.png'

export default function Brunswick(){
    const brands = [brand1, brand2, brand3, brand4, brand5, brand6, brand7, brand8, brand9, brand10]
    return(
        <div className="flex-parent" style={{display:"flex", flexDirection:"column", alignItems:"center", width: "100vw", padding:"0vw 10vw"}}>
            <SubPageHero texture_img={texture_img} hero_img={brunswick_hero} title={"Brunswick"}/>
            <Paragraph heading="INNOVATION AND INSPIRATION ON THE WATER" 
            paragraphs={["Brunswick is an industry leader in the marine space with a mission to enhance the experiences of fun-seekers through its brands and boating products. Brunswick is over 150 years old and manages over 80 brands in the marine boat and engine arenas."]}/>
            <TableCellWithImages header="BRANDS" images={brands}/>
            <Paragraph heading="HISTORY" subheading={"A deep history of innovation"} 
            paragraphs={["For more than 150 years, Brunswick has been a major innovator in both the marine space and in other diverse industries such as fitness and aerospace. The company created its first billiards table in 1845, which proved to be massively successful for the fledgling company. Brunswick then quickly began to use its expertise in wood work to enter new fields, such as bar backs and--quite notably--bowling. Later, the product lineup began to include bowling balls, pins, and other related equipment. As Brunswick continued its growth, it made Chicago its headquarters. After acquiring other businesses, Brunswick ventured further into the recreation industry by making other products such as golf clubs, roller skates, fishing equipment, basketballs and more.",
                "As a conglomerate, Brunswick has developed solutions in many different industries and has created products and innovations that have helped to change the world. The company advanced medical devices, namely syringes and catheters, supported World War Two by producing engines, and even aided space exploration efforts with parts, filters, and seals for Space Shuttles and Mars landers. As time went on, Brunswick focused its core business around recreation experiences and today is focused on being the leading company within the marine space."]}/>
            <Quote2 background_img={brunswick} img={david_foulkes} heading="STRATEGIC DIRECTION" subheading="Guided by years of experience"
                info="David Foulkes, Brunswick CEO" 
                paragraphs={["Historically, David has been an aspirational leader within Brunswick. In the past, he served in Mercury Marine as the VP of PD&E (Product Design & Engineering), and also as Brunswick's CTO. David helped to advance the company’s technical expertise faster and more in-depth than ever before.",
                "Now as the Chief Executive Officer, David has charted a course for Brunswick that doubles down on innovative features and experiences. He has a desire to inspire our customers with exemplary product craftsmanship and attention to detail."]}
            />
            <PageNav img1={brunswick1} img2={brunswick2} heading1="WHAT WE DO" subheading1="See Us in Action" url1="/what-we-do" heading2="BLOG" subheading2="Read Our Thoughts" url2="/blog"/>
            <Footer />
        </div>
    );
}