/*
 * paragraph.js
 * Author: Lily Li
 */

import React from 'react';
import '../../css/theme.css'
import {motion} from 'framer-motion'

export default function Paragraph(props){
    var paragraphs = []
    for(var p in props.paragraphs){
        paragraphs.push(<p className="paragraph" style={{marginTop:"10px"}}>{props.paragraphs[p]}</p>);
    }
    return(
        <motion.div
        x={"-100%"}
        animate={{ x:0 }}
        transition={{ duration: 0.5 }}
        >
            <div style={{width:"100%",display:"flex", flexDirection:"column", justifyItems:"center"}}>
                {props.heading && <h4 style={{paddingLeft:"20px",paddingRight:"20px"}}>{props.heading}</h4>} 
                {props.subheading && <div className="subheading">{props.subheading}</div>}
                {paragraphs}
            </div>
        </motion.div>
    );
}
