/*
 * snippet.js
 * Author: Lily Li
 */

import React from 'react';
import './snippet.css';
import {SnippetNewsletter} from '../newslettertable/newsletter_table'
import {motion} from 'framer-motion'
import Insta from '../insta/insta.js'
import '../../css/theme.css';

import img1 from '../../assets/1.png'
import img2 from '../../assets/2.jpg'
import img3 from '../../assets/3.JPG'
import img4 from '../../assets/4.jpg'
import img5 from '../../assets/5.jpg'
import img6 from '../../assets/6.jpg'
import img7 from '../../assets/7.jpg'
import img8 from '../../assets/8.jpg'
import img9 from '../../assets/9.jpg'

import footer_texture from '../../assets/home-texture.png'



export default function Snippet(props){
    return(
        <motion.div
            x={"-100%"}
            animate={{ x:0 }}
        transition={{ duration: 0.5 }}
        id="snippet-div" 
        >
            <NewsletterSnippet blog_img={props.blog_img} blog_title={props.blog_title} blog_links={props.blog_links}/>
            <ImgSpinnet pics_img={props.pics_img} pics_title={props.pics_title}/>       
        </motion.div>     
    );
}

function NewsletterSnippet(props){
    return(
        <div id="snippet-col-div">
            <div id="snippet-col-header">
                <img id="snippet-header-img" src={props.blog_img}/>
                <h3 style={{zIndex:'500', opacity:"1"}}>{props.blog_title}</h3>
            </div>
            <div id="snippet-content-div" className="custom-scroll-div">
                <SnippetNewsletter style={{height:"100px !important"}} snippet={true}/>
            </div>
        </div>
    )
}

const ImgSpinnet = (props) => {
    const img=[img1, img2, img3, img4, img5, img6, img7, img8, img9];
    var images = [];
    var num_img = img.length;
    for(var i = 0; i < num_img; i++){
        images.push(
            <img src={img[i]} id="snippet-img"/>
        )
    }
    return(
        <div id="snippet-col-div">
            <div id="snippet-col-header">
                <img id="snippet-header-img" src={props.pics_img}/>
                <h3 style={{zIndex:'500', opacity:"1"}}>{props.pics_title}</h3>
            </div>
            <div id="snippet-content-div" className='custom-scroll-div'>
                <Insta/>
            </div>
        </div>
    )
}