/*
 * page_navigation.js
 * Author: Lily Li
 */

import React from 'react';
import './page_navigation.css'
import '../../css/theme.css'

export default function PageNav(props){
    return(
        <div className="pagnav-div">
            <NavCol src={props.img1} heading={props.heading1} subheading={props.subheading1} url={props.url1} class="navcol-right-div"/>
            <NavCol src={props.img2} heading={props.heading2} subheading={props.subheading2} url={props.url2} class="navcol-left-div"/>
        </div>
    )
}

function NavCol(props){
    return(
        <div id="navcol-div" className={props.class} onClick={e => window.open(`${props.url}`,'_self')}>
            <img src={props.src} id="navcol-img"/>
            <h4 style={{paddingLeft:"20px",paddingRight:"20px", color:"white", zIndex:"500"}}>{props.heading}</h4>
            <div className="subheading" style={{color:"var(--blue)", zIndex:"500"}}>{props.subheading}</div>
        </div>
    )
}